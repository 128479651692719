$(() => {
  const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  fancyBoxJquery('[data-fancybox]').fancybox({
    video: {
      tpl:
        '<video class="fancybox-video" controls controlsList="nodownload">' +
        '<source src="{{src}}" type="{{format}}" />' +
        'Sorry, your browser doesn\'t support embedded videos, <a href="{{src}}">download</a> and watch with your favorite video player!' +
        '</video>',
    },
  });

  $(document).on("click", "a.bs-post__trigger[data-fancybox]", (event) => {
    let popupDivID = $(event.currentTarget).data("src");
    let wistiaVideoDiv = $(popupDivID).find(".wistia-video-div");
    if (wistiaVideoDiv.length) {
      wistiaVideoDiv.html("");
      let wistiaVideoId = wistiaVideoDiv.data("id");
      let wistiaVideoContent = `<script src="//fast.wistia.com/embed/medias/` + wistiaVideoId + `.jsonp" async></script>
    <script src="//fast.wistia.com/assets/external/E-v1.js" async></script>
    <div class="wistia_embed wistia_async_` + wistiaVideoId + ` videoFoam=true autoPlay=true">&nbsp;</div>`;
      wistiaVideoDiv.html(wistiaVideoContent);
    }
  });

  $('.bs-post__trigger[data-fancybox], .bs-post__trigger[data-fancybox="true"]').each((index, element) => {
    $(element).on('click', (e) => {
      const returnedFunction = debounce(() => {
        if ($('.fancybox-content').find('.wp-block-embed').length) {
          $('.fancybox-container').addClass('post-popup-video');
          $('.fancybox-content').addClass('video-post');
        }
      }, 10);
      returnedFunction();
    });
  });

});
