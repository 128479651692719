$(() => {
  let groundbreakingSection = () => {
    let $section = $('.bs-section--groundbreaking-technology'),
        $banner = $section.find('.bs-row  > .bs-column:last-child .bs-div'),
        $clonePlace = $section.find('.bs-row  > .bs-column:first-child .bs-div__inner > p');
        
        $banner.clone().insertAfter($clonePlace);
  }
  if ($('.bs-section--groundbreaking-technology').length) {
    groundbreakingSection();
  }
});