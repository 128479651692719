$(() => {
	let ourCultureTitle = () => {
		let $ourCultureSlider = $('.bs-section--our-culture-tab-slider'),
			$ourCultureTitle = $ourCultureSlider.find(
				'.bs-div--tab-slider-title'
			),
			$sliderTabs = $ourCultureSlider.find(
				'.bs-tab-slider .bs-slider-tabs'
			);

		$sliderTabs.find('.slick-slide:first-child').addClass('first-nav-item');
		$sliderTabs.find('.slick-slide:last-child').addClass('last-nav-item');

		if (
			$ourCultureSlider.find(
				'.bs-tab-slider .bs-slider-tabs .bs-div--tab-slider-title'
			).length == 0
		) {
			$sliderTabs.prepend($ourCultureTitle.clone());
			//$ourCultureTitle.remove();
		}
	};

	$('.bs-section--our-culture-tab-slider').each((index, element) => {
		const $this = $(element);
		let $slider, $sliderParent;
		if ($this.find('.bs-tab-slider').length) {
			$slider = $this.find('.bs-slider-content');
			$sliderParent = $slider.parent();
		}
		if ($this.find('.bs-slider').length) {
			$slider = $this.find('.slick-slider');
			$sliderParent = $slider.parent();
		}

		$sliderParent.find('.slick-slider').each((i, ele) => {
			const uniqueSliderId = $(ele).attr('id');
			$('#' + uniqueSliderId).on(
				'init reInit afterChange',
				function (event, slick) {
					if (window.innerWidth > 760) {
						if ($('.bs-section--our-culture-tab-slider').length) {
							ourCultureTitle();
						}
					}
				}
			);
		});
	});
	// $solutionSlider = $('.bs-section--home-solutions .bs-slider-tabs');
	// $solutionSlider.on('afterChange', function () {
	// 	$solutionSlider.find('.slick-slide').each((index, element) => {
	// 		setTimeout(function () {
	// 			$(element).attr('aria-hidden', false);
	// 		}, 100);
	// 	});
	// });

	$('.bs-section--product-slider .slick-slide').each((index, element) => {
		$(element)
			.find('.bs-div__inner h4')
			.clone()
			.prependTo($(element).find('.slick-slide-wrapper'));
	});
});
