require("slick-carousel-latest");
(($) => {
  // Improve scrolling performance
  $.event.special.touchstart = {
    setup: function( _, ns, handle ) {
      this.addEventListener("touchstart", handle, { passive: !ns.includes("noPreventDefault") });
    }
  };
  $.event.special.touchmove = {
    setup: function( _, ns, handle ) {
      this.addEventListener("touchmove", handle, { passive: !ns.includes("noPreventDefault") });
    }
  };
  $.event.special.wheel = {
    setup: function( _, ns, handle ){
      this.addEventListener("wheel", handle, { passive: true });
    }
  };
  $.event.special.mousewheel = {
    setup: function( _, ns, handle ){
      this.addEventListener("mousewheel", handle, { passive: true });
    }
  };

  // Image lazy loading remove from first section of page
  $("section:first-child").find("img").removeAttr("loading");

  $(() => {
    if ($('[data-slick]').length) {
      $('[data-slick]').not('.slick-initialized').slick();
    }
    initSlick();
  });

  const initSlick = () => {
    if ($("[data-slick]").length) {
      $("[data-slick]").not(".slick-initialized").slick();
    }
  }

  const debounce = (func, wait) => {
    let timer;
    return (event) => {
      if (timer) clearTimeout(timer);
      timer = setTimeout(func, wait, event);
    };
  }

  const slickSliderResizeBehavior = () => {
    if ($(".slick-initialized").length) {
      $(".slick-initialized[data-slick]").each((index) => {
        $(".slick-initialized[data-slick]")[index].slick.refresh();
      });
    }
  };

  $(window).resize(
    debounce(() => {
      initSlick();
      slickSliderResizeBehavior();
    }, 300)
  );
})(jQuery);