import { CountUp } from '../../node_modules/countup.js/dist/countUp.js';
$(() => {
	const $homeSlider = $('.bs-tab-slider--home-solutions');

	$homeSlider.find('.bs-slider-content .slick-next').click(() => {
		if ($(window).width() >= 992) {
			let transformVal = $homeSlider
				.find('.bs-slider-tabs .slick-slide')
				.outerWidth(true);
			let transformMatrix = $homeSlider
				.find('.bs-slider-tabs .slick-track')
				.css('transform');
			let matrix = transformMatrix.replace(/[^0-9\-.,]/g, '').split(',');
			let newVal = parseInt(matrix[4]) - transformVal;
			$homeSlider.find('.bs-slider-tabs .slick-track').css({
				transform: 'translate3d(' + newVal + 'px, 0px, 0px)',
			});
		}
	});
});
