import { CountUp } from '../../node_modules/countup.js/dist/countUp.js';
$(() => {
	const chooseFromSlider = () => {
		const $chooseFromSlider = $('.bs-tab-slider--choose-from-services');
		$chooseFromSlider
			.find('.bs-slider-content[data-slick]')
			.each((i, ele) => {
				const uniqueSliderId = $(ele).attr('id');
				$('#' + uniqueSliderId).on(
					'init reInit afterChange',
					function (event, slick, currentSlide) {
						let item = $(slick.$slides[currentSlide])
							.find('.bs-counter__count > span')
							.get(0);
						let dataOptions = item.getAttribute('data-options');
						let counterOptions = JSON.parse(dataOptions);
						const countUp = new CountUp(
							item,
							counterOptions.endVal,
							counterOptions
						);
						if (!countUp.error) {
							countUp.start();
						} else {
							console.error(countUp.error);
						}
					}
				);
			});
	};

	$(window).on('load resize', () => {
		chooseFromSlider();
	});
});
