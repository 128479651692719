$(() => {
	const eventFeatured = $('.bs-section--events-featured .bs-posts__featured');
	const innerMenuWrapper = eventFeatured.find('.bs-post__inner');
	innerMenuWrapper.each((index, element) => {
		$(element)
			.find('.bs-post-event_date, .bs-post-taxonomy_event-location')
			.wrapAll('<div class="bs-post__taxonomy-wrapper" />');
		$(element)
			.find('.bs-post__taxonomy-wrapper, .bs-post-taxonomy_events-type')
			.wrapAll('<div class="bs-post__taxonomy-wrapper-main" />');
	});

	const productsInnerWrap = $('.bs-section--products');
	const innerWrap = productsInnerWrap.find('.bs-div--products');
	innerWrap.each((index, element) => {
		$(element)
			.find('h4, p, ul')
			.wrapAll('<div class="bs-div__inner-wrapper" />');
	});

	//blurbs common wrapper
	const blurbsNeedWrapper = $('.bs-section--blurbs-need-wrapper');
	const blurbsInnerWrapper = blurbsNeedWrapper.find('.bs-blurb');
	blurbsInnerWrapper.each((index, element) => {
		$(element)
			.find('.bs-blurb__title, .bs-blurb__description, .bs-blurb__read-more')
			.wrapAll('<div class="bs-blurb__inner-wrapper" />');
	});

		//card content common wrapper
		const cardNeedWrapper = $('.bs-section--card-need-wrapper');
		const cardInnerWrapper = cardNeedWrapper.find('.bs-card');
		cardInnerWrapper.each((index, element) => {
			$(element)
			.find('.bs-card__title, .bs-card__description')
			.wrapAll("<div class='bs-card__inner-content' />");
		});
});
