$(() => {
	const smoothscroll = () => {
		const speed = 500;
		const postBlockFilterFields = $('.bs-posts .bs-posts__filters--fields');

		if (postBlockFilterFields.length) {
			const searchParams = new URLSearchParams(window.location.search);
			if (
				searchParams.has('events-type') ||
				searchParams.has('event-product-type') ||
				searchParams.has('search')
			) {
				$('html, body').animate(
					{ scrollTop: $(postBlockFilterFields).offset().top - 130 },
					speed
				);
			}
		}
	};
	const postAppendWrapper = () => {
		const eventNormalPost = $('.bs-post-block--events .bs-posts__normal');
		const innerMenuWrapper = eventNormalPost.find('.bs-post__inner');
		innerMenuWrapper.each((index, element) => {
			if ($(element).find('.bs-post__taxonomy-wrapper').length == 0) {
				$(element)
					.find(
						'.bs-post-event_date, .bs-post-taxonomy_event-location'
					)
					.wrapAll('<div class="bs-post__taxonomy-wrapper" />');
			}
		});
	};
	const facetPostAppendWrapper = () => {
		const eventNormalPost = $(
			'.bs-section--resource-block .facetwp-template:not([data-name="upcoming_event_list"]):not([data-name="newsroom_list"]):not([data-name="press_release_list"]) .bs-post'
		);
		const innerMenuWrapper = eventNormalPost.find('.bs-post__inner');
		innerMenuWrapper.each((index, element) => {
			if ($(element).find('.bs-post__taxonomy-wrap').length == 0) {
				$(element)
					.find('.bs-post__category, .bs-post__date')
					.wrapAll('<div class="bs-post__taxonomy-wrap" />');
			}
		});
	};
	const placeholder = $('input.search-box').attr('placeholder');
	$('input.search-box').attr('placeholder', placeholder + '...');
	$('.bs-posts__filters select').on('select2:select select2:unselect', () => {
		$('.bs-posts__filters form .filter-submit input').click();
		smoothscroll();
	});
	smoothscroll();
	postAppendWrapper();
	facetPostAppendWrapper();

	$('body').on(
		'DOMSubtreeModified',
		'.bs-post-block--events .bs-posts__container',
		() => {
			postAppendWrapper();
		}
	);
	$('body').on('DOMSubtreeModified', '.facetwp-template', () => {
		facetPostAppendWrapper();
	});
});
$(window).on('load', () => {
	// Post block scroll to top of the section when pagination load
	const speed = 500;
	const postListTop = $('.bs-section--past-events');
	if (postListTop.length) {
		const pathname = window.location.pathname;
		const pathArray = pathname.split('/');
		if (pathArray.includes('page')) {
			$('html, body').animate(
				{ scrollTop: $(postListTop).offset().top - 100 },
				speed
			);
		}
	}
});
