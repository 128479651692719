(($) => {
  $(() => {
    let formatCounter = (counterSection) => {
      let $counter = counterSection.find('.bs-counter'),
          countWidth = 0;
      $counter.each((index, counter) => {
        let title = $(counter).find('.bs-counter__title'),
            count = $(counter).find('.bs-counter__count'),
            postfix = $(counter).find('.bs-counter__postfix'),
            prefix = $(counter).find('.bs-counter__prefix'),
            summary = $(counter).find('.bs-counter__summary');
        count.prepend(prefix.html());
        count.append(postfix.html());
        let countUpdated = $(counter).find('.bs-counter__count');
        if(countWidth < countUpdated.outerWidth()){
          countWidth = countUpdated.outerWidth();
        }
        if (title.length) {
          title.append(summary.html());
        }
        summary.hide();
        postfix.hide();
        prefix.hide()
      });
      $counter.find('.bs-counter__count').width(countWidth + 35);
    }
    if ($('.bs-section--industry-counter').length) {
      formatCounter($('.bs-section--industry-counter'));
    }
  });
})(jQuery);