$(() => {
  let footerMobileMenu = () => {
    let $footerNav = $('.footer__nav-other'),
      $accordionItem = $footerNav.find('> div > ul > li.menu-item-has-children');
    $accordionItem.find('> a').append('<span class="dropdown-icon"></span>');
    $accordionItem.each((index, element) => {

      $(element).find('> a .dropdown-icon').on( 'click', function(e) {
        e.preventDefault();
        let $link = $(this).parent(),
          $subMenu = $link.next('.sub-menu');
        if($subMenu.is(':hidden')){
          $subMenu.slideDown(300, function() {
            $link.addClass('active');
          });
        }else{
          $subMenu.slideUp(300, function() {
            $link.removeClass('active');
          });
        }
      });
    });
  }

  if (window.innerWidth < 760) {
    if ($('.footer__nav-other').length) {
      footerMobileMenu();
    }
  }
})