$(() => {
  $('.bs-section--blog-inner-content').find('.bs-blurb').each((index, element) => {
    $(element).find('.bs-blurb__title, .bs-blurb__subtitle, .bs-blurb__description')
      .wrapAll('<div class="bs-blurb__content"></div>');
  });

  // Media element append into blurb
  $('.bs-section--blurb-media-element').find('.slick-slide-wrapper').each((index, slide) => {
    const mediaElement = $(slide).find('.media-elements');
    mediaElement.insertAfter($(slide).find('.bs-blurb__container div').last());
  });
});

