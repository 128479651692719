$(() => {
	const displayMobileTitle = () => {
		let titleArray = [];
		$(
			'.bs-tab-slider--choose-from-services .bs-slider-tabs .slick-slide-wrapper'
		).each((index, element) => {
			titleArray[index] = $(element).find('h6').text();
		});

		$(
			'.bs-tab-slider--choose-from-services .bs-slider-content .slick-slide'
		).each((index, element) => {
			if ($(window).width() <= 768) {
				$(element).find('h3').remove();
				$('<h3>' + titleArray[index] + '</h3>').prependTo(
					$(element).find('.bs-div:nth-of-type(1) .bs-div__inner')
				);
			}
		});
	};

	$(window).on('load resize', () => {
		displayMobileTitle();
	});
});
