$(() => {
	const header = $('header');
	const solutionMenu = $(
		'.dropdown-type-two > .mega-sub-menu .mega-menu-column:first-of-type 	>.mega-sub-menu >li:nth-of-type(even)'
	);
	const solutionSubmenu2 = $(
		'.dropdown-type-two > .mega-sub-menu .mega-menu-column:first-of-type 	>.mega-sub-menu >li:nth-of-type(even) .mega-menu-item-has-children'
	);
	const solutionSubmenu1 = $(
		'.dropdown-type-two > .mega-sub-menu .mega-menu-column:first-of-type 	>.mega-sub-menu >li:nth-of-type(odd) .mega-menu-item-has-children'
	);

	if ($('.transparent-header').length > 0) {
		if (window.pageYOffset >= 1) {
			header.addClass('bs-sticky');
		}

		$(window).scroll(() => {
			if (window.pageYOffset >= 1) {
				header.addClass('bs-sticky');
			} else {
				header.removeClass('bs-sticky');
			}
		});
	} else {
		header.addClass('bs-sticky');
	}

	let devices = window.matchMedia('(max-width: 1199px)');
	let desktop = window.matchMedia('(min-width: 1200px)');
	let topMenuArray = '';
	topMenuArray = $('header .navbar-top #mega-menu-top-menu').clone().html();

	const appendTopMenu = () => {
		if ($('#mega-menu-main-menu li.appendedMenu').length == 0) {
			$('header .navbar-top #mega-menu-top-menu > li').each(
				(index, element) => {
					const $this = $(element);
					$this.appendTo('.nav-main #mega-menu-main-menu');
				}
			);
		}
	};
	const appendTopMenuDesktop = () => {
		$('.nav-main #mega-menu-main-menu li.appendedMenu').remove();
		if ($('#mega-menu-top-menu li').length == 0) {
			$(topMenuArray).appendTo('.nav-top #mega-menu-top-menu');
		}
	};
	$(window).on('load resize', () => {
		if (devices.matches) {
			appendTopMenu();
		} else if (desktop.matches) {
			appendTopMenuDesktop();
		}
	});

	const hideSearchbar = () => {
		$('.search-icon form')
			.removeClass('mega-search-open')
			.addClass('mega-search-closed');
	};

	header.mouseleave(() => {
		hideSearchbar();
	});
	solutionSubmenu1.mouseleave(() => {
		solutionMenu.removeClass('bs-opacity');
	});
	solutionSubmenu1.mouseenter(() => {
		solutionMenu.addClass('bs-opacity');
	});
	solutionSubmenu2.mouseleave(() => {
		$('.nav-content-block').removeClass('bs-opacity');
	});
	solutionSubmenu2.mouseenter(() => {
		$('.nav-content-block').addClass('bs-opacity');
	});

	header
		.find('form.mega-search')
		.append('<span class="search-close"></span>');

	$(document).on('click', '.search-close', () => {
		hideSearchbar();
	});
});
