$(() => {
	const debounce = (func, wait) => {
		let timeout;
		return function executedFunction(...args) {
			const later = () => {
				clearTimeout(timeout);
				func(...args);
			};
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
		};
	};

	$('.bs-section--faq-content .accordion__block__btn').each(function () {
		$(this).on('click', function () {
			if($(this).parent().hasClass('active')){
				const returnedFunction = debounce(() => {
					const changePosition = $(this).offset().top - 200;
					$('html, body').animate({ scrollTop: changePosition }, 800);
				}, 400);
				returnedFunction();
			}
		});
	});
});
