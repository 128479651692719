$(() => {
  const essemtecSection = $('.bs-section--essemtec-image-content');
  $(essemtecSection).each(function () {
    const title = $(this).find('.bs-column > h3, .bs-column > h2' );
    const description = $(this).find('.bs-column > p');
    const imageCard = $(this).find('.bs-div--essemtec-image-card');
    $(description).clone().prependTo(imageCard);
    $(title).clone().prependTo(imageCard);
  });

  const essemtecSiderSection = $('.bs-section--essemtec-slider');
  $(essemtecSiderSection).each(function () {
    const detailCard = $(this).find('.bs-slider-tabs .slick-slide .slick-slide-wrapper .bs-div--essemtec-detail-card');
    const imageCards = $(this).find('.bs-slider-content .slick-slide .slick-slide-wrapper .bs-div--essemtec-image-card');
    $(imageCards).each(function (index) {
      $(detailCard[index]).clone().appendTo($(this));
    });
  });
});
