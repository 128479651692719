$(() => {
	const popups = $('.bs-section--leadership-members .bs-post__target--popup-leadership');
	
	$(popups).each((index, element)=>{
		let linkedin = $(element).find('.bs-post__details .media-elements');
		let media = $(element).find('.bs-post__image figure');
		$(media).append(linkedin);
	});

	$('.bs-section--leadership-members .bs-post.leadership').each((index, element)=>{
		let link = $(element).find('.bs-post__target--popup-leadership .bs-post__image figure .media-elements a').attr('href');
		if(typeof link == 'undefined'){
			$(element).addClass('no-link');
		}
	});
});
