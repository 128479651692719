$(() => {
  const FwTabSliderMobile = () => {
    $(".bs-section--we-have-solved").each((j, mainSlider) => {
      // check mobile screen
      if (window.innerWidth < 992) {
        // Loop tab slides
        $(mainSlider)
          .find(".bs-slider-tabs .slick-slide")
          .each((index, tabSlide) => {
            const slideIndex = $(tabSlide).data("slick-index");
            const contentSlideWrapper = $(mainSlider).find(
              `.bs-slider-content .slick-slide .slick-slide-wrapper[data-slick-index='${slideIndex}']`
            );
            const newTabContent = $(tabSlide)
              .find(".slick-slide-wrapper")
              .html();
            // Append only if not exists on content
            if (
              contentSlideWrapper.find(
                "h4"
              ).length == 0
            ) {
              contentSlideWrapper.prepend(newTabContent);
            }
          });
      } else {
        // Loop content slides
        $(mainSlider)
          .find(".bs-slider-content .slick-slide .slick-slide-wrapper")
          .each((index, contentSlideWrapper) => {
            // Remove already appended content from tab slider
            $(contentSlideWrapper)
              .find("h4")
              .remove();
          });
      }
    });
  };

  $(window).on("load", () => {
    FwTabSliderMobile();
  });

  // resize event fire on drag end
  window.addEventListener(
    "resize",
    debounce((e) => {
      // Image accordion function
      FwTabSliderMobile();
    })
  );

  // set resize event delay for drag
  function debounce(func) {
    var timer;
    return (event) => {
      if (timer) clearTimeout(timer);
      timer = setTimeout(func, 100, event);
    };
  }
});
