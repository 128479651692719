$(() => {
  $('.bs-posts__filter--partner-category #partner-category').prepend(
    '<option value="" disabled selected hidden>By Type</option>'
  );

  const addPlayIcon = () => {
    const playIconTypes = ['Videos'];
    // Add play button to video and Webinar on Single post
    $('.bs-post .bs-post__category span').each((index, element) => {
      const typeText = $(element).text();
      if (playIconTypes.includes(typeText)) {
        $(element).closest('.bs-post').addClass('video');
      }
    });
  };

  // Add play icon when page load
  $(window).on('load', () => {
    addPlayIcon();
  });

  // facet ajax load
  $(document).on('facetwp-loaded', () => {
    if ($('.facetwp-template[data-name="patent_list"]').length === 0 && $('.facetwp-template[data-name="upcoming_event_list"]').length === 0) {
      const scrollValue =
        $('.facetwp-template').closest('section').offset().top -
        100;
      $('html, body').animate({scrollTop: scrollValue}, 500);
      addPlayIcon();
    }
  });

  // Remove anchor from media-executive
  $('.bs-single-post--media-executive .bs-post__inner').unwrap();
  $('.bs-section--media-executive .bs-post__target--popup').remove();
});
