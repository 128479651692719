$(() => {
  $('.bs-section--mobile-progress-bar').each((index, element) => {
    const $this = $(element);
    let $slider, $sliderParent;
    if ($this.find('.bs-tab-slider').length) {
      $slider = $this.find('.bs-slider-content');
      $sliderParent = $slider.parent();
    }
    if ($this.find('.bs-slider').length) {
      $slider = $this.find('[data-slick]');
      $sliderParent = $slider.parent();
    }
    if (!$this.find('.mobile-progress-bar-container').length) {
      $sliderParent.append(
        `<div class="mobile-progress-bar-container"><div class="progress-bar-wrapper"><span class="progress-inner">
            </span></div><span class="progress-bar-slide-count"></span></div>`
      );
    }
    $sliderParent.find('[data-slick]').each((i, ele) => {
      const uniqueSliderId = $(ele).attr('id');
      $('#' + uniqueSliderId).on('init reInit afterChange', function (event, slick) {
          const currentSlideNumber =
            (slick.currentSlide ? slick.currentSlide : 0) + 1;
          const totalSlideCount = slick.slideCount
            ? slick.slideCount
            : 0;
          const progressBarValue =
            (currentSlideNumber / totalSlideCount) * 100 +
            '%';
          const progressBarSlideCount = $('#' + uniqueSliderId)
            .parent($sliderParent)
            .find('.progress-bar-slide-count');
          progressBarSlideCount.text(
            currentSlideNumber + ' / ' + totalSlideCount
          );
          $('#' + uniqueSliderId)
            .parent($sliderParent)
            .find('.progress-inner')
            .css({width: progressBarValue});
        }
      );
    });
  });

  $(window).on('load', () => {
    $(".bs-section--mobile-progress-bar [data-slick]").each((index, ele) => {
      if (ele.slick.breakpointSettings[ele.slick.activeBreakpoint] !== "unslick") {
        ele.slick.refresh();
      }
    });
  });
});